import "./App.css";
import NavBar from "./Components/NavBar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import HomePage from "./Components/HomePage";
import backgroundImage from "./images/bgimg.webp";
import backgroundImage2 from "./images/background2.png";
import AboutUs from "./Components/AboutUs";
import HowToBuy from "./Components/HowToBuy";

function AppContent() {
  const [activeSection, setActiveSection] = useState("/");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 100; // Adding offset for navbar

      // Get sections
      const homeSection = document.getElementById("home");
      const aboutSection = document.getElementById("AboutUs");
      const buySection = document.getElementById("HowToBuy");

      // Check which section is in view
      if (homeSection && scrollPosition < aboutSection?.offsetTop) {
        setActiveSection("/");
      } else if (aboutSection && scrollPosition < buySection?.offsetTop) {
        setActiveSection("#AboutUs");
      } else if (buySection) {
        setActiveSection("#HowToBuy");
      }
    };

    window.addEventListener("scroll", handleScroll);
    // Trigger once on mount to set initial active section
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="App">
      <NavBar activeSection={activeSection} />
      <div id="home" className="relative bg-black min-h-screen">
        <div
          style={{ backgroundImage: `url(${backgroundImage})` }}
          className="absolute inset-0 bg-cover bg-center opacity-30"
        ></div>
        <div className="relative z-10 py-10 sm:py-20">
          <Routes>
            <Route path="/" element={<HomePage />} />
          </Routes>
        </div>
      </div>
      <div id="AboutUs" className="relative bg-black min-h-screen">
        <div
          style={{ backgroundImage: `url(${backgroundImage2})` }}
          className="absolute inset-0 bg-cover bg-center opacity-30"
        ></div>
        <div className="relative z-10 sm:py-4">
          <AboutUs />
        </div>
      </div>
      <div
        id="HowToBuy"
        className="relative bg-black min-h-screen py-16 sm:py-20"
      >
        <HowToBuy />
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
