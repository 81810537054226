import React, { useState } from "react";
import logo from "../images/logo11.png";

function NavBar({ activeSection }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const NavBarStyle = {
    backgroundColor: "#384116",
    borderBottomLeftRadius: "25px",
  };
  const NavTextStyle = {
    color: "white",
    fontFamily: "'SfDistantGalaxy', sans-serif",
  };
  const LogoTextStyle = {
    color: "#A47447",
    fontFamily: "'SfDistantGalaxy', sans-serif",
  };
  const NavTextStyleHover = {
    backgroundColor: "#A47447",
    fontFamily: "'SfDistantGalaxy', sans-serif",
    color: "white",
    borderRadius: "0.5rem",
  };

  const scrollToSection = (e, sectionId) => {
    e.preventDefault();
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <nav className="fixed top-0 w-full z-50">
        <div>
          <div className="relative flex h-20">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                type="button"
                className="relative inline-flex items-center justify-center rounded-md p-2 text-[#A47447] hover:bg-[#384116] hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              >
                <span className="absolute -inset-0.5"></span>
                <span className="sr-only">Open main menu</span>
                <svg
                  className={`${isMobileMenuOpen ? "hidden" : "block"} h-6 w-6`}
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
                <svg
                  className={`${isMobileMenuOpen ? "block" : "hidden"} h-6 w-6`}
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-auto items-center justify-center md:justify-between">
              <div className="flex justify-center sm:justify-start items-center mx-10">
                <img className="h-16 w-auto" src={logo} alt="Yodawifhat" />
                <h1 style={LogoTextStyle}>YODAWIFHAT</h1>
              </div>
              <div
                className="hidden sm:block h-20 w-2/3 flex items-center"
                style={NavBarStyle}
              >
                <div className="flex justify-center items-center space-x-4">
                  <a
                    className="flex items-center nav-link my-3 md:my-4 lg:my-3 sm:text-sm sm:py-1 sm:px-5 md:text-md md:py-1 md:px-10 lg:text-lg lg:py-1 lg:px-20"
                    href="#home"
                    onClick={(e) => scrollToSection(e, "home")}
                    style={
                      activeSection === "/" ? NavTextStyleHover : NavTextStyle
                    }
                  >
                    Home
                  </a>
                  <a
                    className="nav-link sm:text-sm sm:py-1 sm:px-5 md:text-md md:py-1 md:px-10 lg:text-lg lg:py-1 lg:px-20"
                    href="#AboutUs"
                    onClick={(e) => scrollToSection(e, "AboutUs")}
                    style={
                      activeSection === "#AboutUs"
                        ? NavTextStyleHover
                        : NavTextStyle
                    }
                  >
                    Communtiy
                  </a>
                  <a
                    className="nav-link sm:text-sm sm:py-1 sm:px-5 md:text-md md:py-1 md:px-10 lg:text-lg lg:py-1 lg:px-20"
                    href="#HowToBuy"
                    onClick={(e) => scrollToSection(e, "HowToBuy")}
                    style={
                      activeSection === "#HowToBuy"
                        ? NavTextStyleHover
                        : NavTextStyle
                    }
                  >
                    How to buy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${isMobileMenuOpen ? "block" : "hidden"} sm:hidden`}
          id="mobile-menu"
        >
          <div
            className="px-2 pb-3 pt-2"
            style={{ backgroundColor: "#384116" }}
          >
            <a
              className="nav-link px-20 py-2"
              href="#home"
              onClick={(e) => {
                scrollToSection(e, "home");
                setIsMobileMenuOpen(!isMobileMenuOpen);
              }}
              style={activeSection === "/" ? NavTextStyleHover : NavTextStyle}
            >
              Home
            </a>
            <a
              className="nav-link px-20 py-2"
              href="#AboutUs"
              onClick={(e) => {
                scrollToSection(e, "AboutUs");
                setIsMobileMenuOpen(!isMobileMenuOpen);
              }}
              style={
                activeSection === "#AboutUs" ? NavTextStyleHover : NavTextStyle
              }
            >
              Communtiy
            </a>
            <a
              className="nav-link px-20 py-2"
              href="#HowToBuy"
              onClick={(e) => {
                scrollToSection(e, "HowToBuy");
                setIsMobileMenuOpen(!isMobileMenuOpen);
              }}
              style={
                activeSection === "#HowToBuy" ? NavTextStyleHover : NavTextStyle
              }
            >
              How to buy
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
