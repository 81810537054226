import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function AboutUs() {
  const LogoTextStyle = {
    color: "#A47447",
    fontFamily: "'SfDistantGalaxy', sans-serif",
  };
  const TextStyle = {
    color: "white",
    fontFamily: "'SfDistantGalaxy', sans-serif",
  };
  const ButtonStyle = {
    backgroundColor: "#384116",
    color: "white",
    fontFamily: "'SfDistantGalaxy', sans-serif",
    borderRadius: "1rem",
  };

  const textContainerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.3, // Delay between each child animation
      },
    },
  };

  const textLineVariants = {
    hidden: { x: -100, opacity: 0 }, // Start off-screen to the left
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100, // Spring effect
        damping: 10,
      },
    },
  };

  return (
    <div className="container px-5 py-5" id="AboutUs">
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.7 }}
        variants={textContainerVariants}
      >
        <motion.h1
          style={LogoTextStyle}
          className="sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl my-4"
          variants={textLineVariants}
        >
          WELCOME TO THE WORLD OF YODAWIFHAT
        </motion.h1>
        <motion.h1
          style={TextStyle}
          className="sm:text-2xl md:text-3xl lg:text-4xl xl:text-4xl my-4"
          variants={textLineVariants}
        >
          Yodawifhat, the next-generation memecoin that's here to make waves in
          the crypto universe! Inspired by the wisdom of Yoda and fueled by the
          power of community and fun, Yodawifhat is more than just a digital
          asset—it’s a movement. Born from the love of internet culture and
          powered by Solana blockchain, Yodawifhat is designed to bring together
          meme lovers, crypto enthusiasts, and adventurers alike. Get ready to
          dive into a galaxy where fun meets financial freedom!
        </motion.h1>
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.7 }}
        variants={textContainerVariants}
      >
        <motion.div variants={textLineVariants}>
          <Link
            className="btn btn-lg sm:px-2 sm:my-1 lg:px-4 lg:my-3 sm:text-md md:text-3xl lg:text-4xl xl:text-5xl"
            to="/services"
            style={ButtonStyle}
          >
            JOIN NOW
          </Link>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default AboutUs;
