import React from "react";
import { motion } from "framer-motion";
import phantom from "../images/phantom.png";
import solana from "../images/solana.png";
import logo from "../images/logo11.png";

function HowToBuy() {
  const LogoTextStyle = {
    color: "#A47447",
    fontFamily: "'SfDistantGalaxy', sans-serif",
  };
  const ButtonStyle = {
    backgroundColor: "#384116",
    color: "white",
    fontFamily: "'SfDistantGalaxy', sans-serif",
    borderRadius: "1rem",
  };
  const TextStyle = {
    color: "#384116",
    fontFamily: "'SfDistantGalaxy', sans-serif",
  };
  const TextStyleWhite = {
    color: "white",
    fontFamily: "'SfDistantGalaxy', sans-serif",
  };

  const slideInVariants = {
    hidden: { x: -200, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50 },
    },
  };

  return (
    <div className="px-5" id="HowToBuy">
      <motion.div
        style={LogoTextStyle}
        className="text-3xl md:text-6xl lg:text-7xl xl:text-9xl py-3 md:py-5"
        initial="hidden"
        whileInView="visible"
        variants={slideInVariants}
      >
        HOW TO BUY?
      </motion.div>

      {/* Step 1: Get Phantom Wallet */}
      <div className="grid sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 md:gap-4 h-full mb-2">
        <div className="col-span-1"></div>
        <div className="sm:col-span-10 md:col-span-10 lg:col-span-10 flex items-center justify-center md:justify-start">
          <a
            href="https://phantom.app/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center"
          >
            <motion.img
              src={phantom}
              alt="Phantom Wallet"
              className="h-16 w-auto mr-4 rounded-lg"
              initial="hidden"
              whileInView="visible"
              variants={slideInVariants}
            />
          </a>
          <motion.h1
            className="sm:text-3xl md:text-5xl lg:text-6xl xl:text-7xl text-left"
            style={TextStyle}
            initial="hidden"
            whileInView="visible"
            variants={slideInVariants}
          >
            Get Phantom Wallet
          </motion.h1>
        </div>
        <div className="col-span-1"></div>
      </div>
      <div>
        <div className="grid sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 md:md:gap-4 h-full mb-4">
          <div className="col-span-1"></div>
          <motion.div
            className="sm:col-span-10 md:col-span-10 lg:col-span-10 sm:py-3 md:py-4 lg:py-5 flex flex-col justify-start items-start"
            initial="hidden"
            whileInView="visible"
            variants={slideInVariants}
          >
            <h1
              className="sm:text-2xl md:text-4xl lg:text-5xl sm:text-center md:text-left"
              style={TextStyleWhite}
            >
              Download and set up your Phantom wallet, the gateway to buying and
              holding Yodawifhat. Available on desktop and mobile, it's the
              easiest way to manage your Solana coins.
            </h1>
          </motion.div>
          <div className="col-span-1"></div>
        </div>
      </div>

      {/* Step 2: Add Solana (SOL) */}
      <div className="grid sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 md:gap-4 h-full mb-2">
        <div className="col-span-1"></div>
        <div className="sm:col-span-10 md:col-span-10 lg:col-span-10 flex items-center justify-center md:justify-start">
          <a
            href="https://solana.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center"
          >
            <motion.img
              src={solana}
              alt="Solana"
              className="h-16 w-auto mr-4 rounded-lg"
              initial="hidden"
              whileInView="visible"
              variants={slideInVariants}
            />
          </a>
          <motion.h1
            className="sm:text-3xl md:text-5xl lg:text-6xl xl:text-7xl text-left"
            style={TextStyle}
            initial="hidden"
            whileInView="visible"
            variants={slideInVariants}
          >
            Add Solana (SOL)
          </motion.h1>
        </div>
        <div className="col-span-1"></div>
      </div>
      <div>
        <div className="grid sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 md:gap-4 h-full mb-4">
          <div className="col-span-1"></div>
          <motion.div
            className="sm:col-span-10 md:col-span-10 lg:col-span-10 sm:py-3 md:py-4 lg:py-5 flex flex-col justify-start items-start"
            initial="hidden"
            whileInView="visible"
            variants={slideInVariants}
          >
            <h1
              className="sm:text-2xl md:text-4xl lg:text-5xl sm:text-center md:text-left"
              style={TextStyleWhite}
            >
              Before you can get your hands on Yodawifhat, you need to load your
              Phantom wallet with Solana (SOL). You can buy SOL on major
              exchanges like Binance, Coinbase, or directly within the Phantom
              wallet.
            </h1>
          </motion.div>
          <div className="col-span-1"></div>
        </div>
      </div>

      {/* Step 3: Purchase Yodawifhat */}
      <div className="grid sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 md:gap-4 h-full mb-2">
        <div className="col-span-1"></div>
        <div className="sm:col-span-10 md:col-span-10 lg:col-span-10 flex items-center justify-center md:justify-start">
          <a
            href="https://yodawifhat.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center"
          >
            <motion.img
              src={logo}
              alt="Yodawifhat Logo"
              className="h-16 w-auto mr-4 rounded-md"
              initial="hidden"
              whileInView="visible"
              variants={slideInVariants}
            />
          </a>
          <motion.h1
            className="sm:text-3xl md:text-5xl lg:text-6xl xl:text-7xl text-left"
            style={TextStyle}
            initial="hidden"
            whileInView="visible"
            variants={slideInVariants}
          >
            Purchase Yodawifhat
          </motion.h1>
        </div>
        <div className="col-span-1"></div>
      </div>
      <div>
        <div className="grid sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 md:gap-4 h-full mb-4">
          <div className="col-span-1"></div>
          <motion.div
            className="sm:col-span-10 md:col-span-10 lg:col-span-10 sm:py-3 md:py-4 lg:py-5 flex flex-col justify-start items-start"
            initial="hidden"
            whileInView="visible"
            variants={slideInVariants}
          >
            <h1
              className="sm:text-2xl md:text-4xl lg:text-5xl sm:text-center md:text-left"
              style={TextStyleWhite}
            >
              Once your wallet is loaded, head to our exchange page. You’ll be
              able to swap your SOL for Yodawifhat in just a few clicks. It’s
              quick, easy, and puts you right in the middle of the memecoin
              universe.
            </h1>
          </motion.div>
          <div className="col-span-1"></div>
        </div>
      </div>
    </div>
  );
}

export default HowToBuy;
