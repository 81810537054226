import React from "react";
import logo from "../images/logo11.png";
import phantom from "../images/phantom.png";
import solana from "../images/solana.png";
import twitter from "../images/twitter.jpg";
import telegram from "../images/telegram.webp";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useState } from "react";
import { FiCopy } from "react-icons/fi";

function HomePage() {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText("BSXwnKZqwawoGspLg551mdKszYVUbuQ96NkKVUYnfo44")
      .then(
        () => {
          setCopySuccess(true);
          setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
        },
        () => {
          alert("Failed to copy text.");
        }
      );
  };
  const LogoTextStyle = {
    color: "#A47447",
    fontFamily: "'SfDistantGalaxy', sans-serif",
  };
  const TextStyle = {
    color: "white",
    fontFamily: "'SfDistantGalaxy', sans-serif",
  };
  const ButtonStyle = {
    display: "flex", // Flexbox to align horizontally
    alignItems: "center", // Center vertically
    justifyContent: "center",
    backgroundColor: "#384116",
    color: "white",
    fontFamily: "'SfDistantGalaxy', sans-serif",
    borderRadius: "1rem",
  };

  const entryVariants = {
    hidden: { y: 50, opacity: 0 }, // Start below and invisible
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100, // Spring effect
        damping: 10,
        duration: 0.8,
      },
    },
  };

  const circularMotionVariants = {
    animate: {
      rotate: [0, -360], // Anti-clockwise rotation
      scale: [1, 0.5, 1], // Reduce size during rotation and then return to original size
      transition: {
        duration: 5, // Total duration for one complete cycle
        ease: "easeInOut",
        repeat: Infinity, // Infinite loop
      },
    },
  };

  const textVariants = {
    hidden: { y: -50, opacity: 0 }, // Start above and invisible
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100, // Spring effect
        damping: 10,
        duration: 0.8,
      },
    },
  };

  return (
    <div className="container min-h-[65vh] py-5 px-10" id="home">
      <div className="grid lg:grid-cols-7 md:grid-cols-5 gap-4 h-full">
        <motion.div
          className="md:col-span-2 lg:col-span-3 flex flex-col justify-center md:items-start sm:items-center"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.7 }}
          variants={textVariants}
        >
          <h1
            className="text-5xl md:text-5xl lg:text-7xl xl:text-8xl"
            style={LogoTextStyle}
          >
            YODAWIFHAT
          </h1>
          <h1
            className="text-lg md:text-2xl lg:text-3xl xl:text-4xl md:text-left"
            style={TextStyle}
          >
            MASTER THE MEME, OWN THE GALAXY!
          </h1>
          <Link
            className="text-xxs md:text-md lg:text-lg xl:text-xl px-1 py-1 my-2 md:px-2 md:py-1 md:my-2 lg:px-5 lg:py-2 lg:my-4"
            to="/"
            style={ButtonStyle}
            onClick={handleCopy}
          >
            <span>BSXwnKZqwawoGspLg551mdKszYVUbuQ96NkKVUYnfo44</span>
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.25rem" }}
            >
              {copySuccess && (
                <span style={{ fontSize: "0.4rem", color: "green" }}>
                  Copied!
                </span>
              )}
            </div>
          </Link>

          <div className="flex items-center justify-center space-x-4 my-4">
            {/* Phantom & Solana side by side */}
            <a
              href="https://phantom.app/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center"
            >
              <motion.img
                src={phantom}
                alt="Phantom"
                className="h-10 w-auto rounded-md"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              />
            </a>
            <a
              href="https://solana.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center"
            >
              <motion.img
                src={solana}
                alt="Solana"
                className="h-10 w-auto rounded-md"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              />
            </a>

            {/* Phantom alone */}
            <a
              href="https://x.com/yodawifhat_?s=11"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center"
            >
              <motion.img
                src={twitter}
                alt="Phantom"
                className="h-10 w-auto rounded-md"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              />
            </a>

            {/* Solana alone */}
            <a
              href="https://t.me/ywifportal"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center"
            >
              <motion.img
                src={telegram}
                alt="Solana"
                className="h-10 w-auto rounded-md"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              />
            </a>

            {/* Phantom alone */}
            <a
              href="https://yodawifhat.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center"
            >
              <motion.img
                src={logo}
                alt="Phantom"
                className="h-10 w-auto rounded-md"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              />
            </a>
          </div>
        </motion.div>
        <div className="lg:col-span-1"></div>
        <motion.div
          className="md:col-span-2 lg:col-span-3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.7 }}
          variants={entryVariants}
        >
          <motion.img
            className="h-auto w-100"
            src={logo}
            alt="Yodawifhat"
            variants={circularMotionVariants}
            animate="animate"
          />
        </motion.div>
      </div>
    </div>
  );
}

export default HomePage;
